import React from "react";
import { getUserSrc, grayImg } from "../../utils";
import "../../css/reservations.css";
import moment from "moment-timezone";

const dateFormat = "dddd DD / MM";

const textColors = {
  lightCard: {
    classDate: {
      cancelled: "text-light",
      normal: "text-dark",
    },
    classTime: {
      cancelled: "text-light",
      normal: "text-dark",
    },
    coach: {
      cancelled: "text-light",
      normal: "text-dark",
    },
  },
  darkCard: {
    classDate: "text-primary",
    classTime: "text-accent",
    coach: "text-accent",
  },
};

const WaitlistRow = ({ waitlist, lightCard, confirmCancel }) => {
  const isCanceled = waitlist?.deletedAt !== null;

  const getClassTime = () => {
    const classTime = moment(waitlist?.single_class?.class_date).utc();
    const startTime = classTime.clone().format("HH:mm");
    const endTime = classTime.clone().add(50, "minutes").format("HH:mm");

    return `${startTime} - ${endTime}`;
  };

  const getCoach = () => {
    if (waitlist?.single_class?.class_instructors?.length > 0) {
      const currentCoach =
        waitlist?.single_class?.class_instructors[0].instructor;
      return currentCoach;
    }
  };

  const getCoachSrc = () => {
    if (getCoach()) {
      const currentCoach = getCoach();
      return getUserSrc(currentCoach);
    } else {
      return grayImg;
    }
  };

  const getCoachNameClassTypeText = () => {
    const currentCoach = getCoach();
    const text = `${currentCoach?.nick_name} - ${waitlist?.single_class?.class_type?.name}`;

    return text;
  };

  const getTextColor = (key) => {
    const reservationStatus = isCanceled ? "cancelled" : "normal";

    if (lightCard) {
      return textColors.lightCard[key][reservationStatus];
    }
    return textColors.darkCard[key];
  };

  const getCoachImgStyle = () => {
    const style = {
      width: "50px",
      height: "50px",
    };

    if (isCanceled) style.filter = "grayscale(100%)";
    return style;
  };

  return (
    <div
      className={`reservation-card card border-0 no-scale px-md-3 rounded-0 border-bottom border-1 border-gray ${
        lightCard ? "bg-accent" : "bg-dark"
      }`}
      style={{ cursor: "default" }}
    >
      <div className="row reservation-card__content align-items-center justify-content-between">
        <div className="d-flex p-0 align-items-center w-max-content">
          <img
            alt="coach"
            src={getCoachSrc(waitlist)}
            style={getCoachImgStyle()}
            className="bg-gray me-2 me-md-4"
          />
          <div className="content__class-data d-flex align-items-center px-0">
            <div className="class-data__date-time d-flex px-0">
              <h2
                style={{ width: "170px" }}
                className={`date-time__date px-0 mb-0 fw-light me-2 me-md-4 ${getTextColor(
                  "classDate"
                )}`}
              >
                {moment(waitlist?.single_class?.class_date).utc().format(dateFormat)}
              </h2>
              <p
                style={{ width: "100px" }}
                className={`date-time__time mb-0 px-0 title-font text-accent fw-normal fs-4 me-4 ${getTextColor(
                  "classTime"
                )}`}
              >
                {getClassTime()}
              </p>
            </div>
            <p
              className={`class-data__coach px-0 mb-0 title-font text-accent w-max-content fw-light fs-5 h-max-content ${getTextColor(
                "coach"
              )}`}
            >
              {getCoachNameClassTypeText()}
            </p>
          </div>
        </div>
        <div className="content__status capitalize mb-0 w-max-content fw-light fs-5">
          <button
            type="button"
            onClick={() => confirmCancel(waitlist)}
            className="content__cancel-btn btn bg-dark w-max-content text-primary fs-5"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default WaitlistRow;
