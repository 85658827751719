import moment from "moment-timezone";

let timezone = null;

moment.updateLocale("es", {
  weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
  weekdaysShort: "Dom_Lun_Mar_Mie_Jue_Vie_Sab".split("_"),
});

export const setupTimezone = payload => timezone = payload;

export const isToday = (dateString) =>
  getLocalMomentObject(dateString).isSame(getLocalMomentObject());

export const sortByDateField = (elements, key, direction = "asc") =>
  elements.sort((a, b) =>
    getLocalMomentObject(a[key]).isBefore(getLocalMomentObject(b[key]))
      ? direction !== "asc"
        ? 1
        : -1
      : direction !== "asc"
      ? -1
      : 1
  );

export const getFriendlyLocalTimeString = (dateString) =>
  getLocalMomentObject(dateString).format("HH:mm");

export const getFriendlyLocalDateString = (dateString) =>
  getLocalMomentObject(dateString).format("DD MMM YYYY");

export const getFriendlyLocalDateTimeString = (dateString) =>
  getLocalMomentObject(dateString).format("DD MMM YYYY HH:mm");

export const getLocalMomentObject = (dateString, format) => timezone !== null && timezone !== "" ?
  moment(dateString, format).tz(timezone) : moment(dateString, format);

//Converts a UTC String date from server response to UTC moment Date
export const convertServerStringDateToUTC = (dateString) =>
  moment.utc(dateString, "YYYY-MM-DD HH:mm:ss");

//Converts Moment UTC Date to Moment Local Date
export const convertMomentUTCToLocal = (utcDate) => utcDate.local();

export const displayTime = (dateString) =>
  convertMomentUTCToLocal(convertServerStringDateToUTC(dateString)).format(
    "HH:mm"
  );

//Converts Moment UTC Date to Moment Local Date

export const convertToUtcString = (dateString) =>
  moment.utc(dateString).format("YYYY-MM-DD HH:mm:ss");

export const displayLocalDateTime = (dateString) =>
  convertMomentUTCToLocal(moment(dateString)).format("YYYY-MM-DD HH:mm:ss");

export const displayDateTime = (dateString) =>
  convertServerStringDateToUTC(dateString).format("YYYY-MM-DD HH:mm:ss");


export const displayDayTime = (dateString) =>
  convertServerStringDateToUTC(dateString).format("ddd DD, HH:mm");

export const displayDayTimeMonth = (dateString) =>
  convertServerStringDateToUTC(dateString).format("ddd DD MMM, HH:mm:ss");
