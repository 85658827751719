import React, { useEffect, useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import ScheduleDay from "./ScheduleDay";

const Schedule = () => {
  const {
    days,
    getSchedule,
    currentDate,
    setCurrentDate,
  } = useContext(SingleClassContext);


  useEffect(() => {
    const initialDate = currentDate.clone().utc(true).subtract(2, "days");
    const start_date = initialDate.clone().utc(true).format();
    const end_date = initialDate.clone().utc(true).add(5, "days").format();

    getSchedule({ start_date, end_date });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const renderClassDays = () => {
    if (Array.isArray(days) && days.length > 0) {
      const currentStringDate = currentDate.clone().format("YYYY-MM-DD");

      return days.map((current) => {
        const isActive = current.date === currentStringDate;

        return (
          <div
            key={current.date}
            className={`${isActive ? "col-4" : "col-2"} mb-5 px-0 text-center schedule-day__container`}
          >
            <ScheduleDay
              single_classes={current.details}
              isActive={isActive}
              date={current.date}
              titleEnabled
            />
          </div>
        );
      });
    }
  };

  const handlePrev = () => {
    setCurrentDate(currentDate.clone().subtract(1, "day"));
  };

  const handleNext = () => {
    setCurrentDate(currentDate.clone().add(1, "day"));
  };

  return (
    <div className="container-fluid px-0 pb-4">
      <div className="row mx-0 align-items-start" style={{}}>
        <div className="col-1">
            <button
              className="btn btn-dark text-primary ps-0"
              onClick={handlePrev}
            >
              <i className="fa fa-chevron-left text-primary fs-3"></i>
            </button>
        </div>

        <div className="col-10">
          <div className="row mx-0 align-items-start" style={{}}>
            {renderClassDays()}
          </div>
        </div>

        <div className="col-1">
            <button
              className="btn  btn-dark text-primary pe-0"
              onClick={handleNext}
            >
              <i className="fa fa-chevron-right text-primary fs-3"></i>
            </button>
        </div>
      </div>
      {/* <ScheduleWeek location={location} days={days} /> */}
    </div>
  );
};

export default Schedule;
